import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import IndexPage from '../components/indexPage'

const UsabilityPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/utilisabilite" type="index">
      <SEO 
        title="Les clefs pour optimiser l’utilisabilité d’un site"
        description="Utilisabilité : quelles sont les marques qui rendent l’expérience de navigation sur leur site la plus simple, pratique et cohérente ?"
        path="/utilisabilite" />

      <Breadcrumb url="/utilisabilite" label="Utilisabilité" />

      <h1 className="sr-only">Utilisabilité</h1>
      <IndexPage slug="usability" type="index" />
    </Layout>
  )
  }

export default UsabilityPage

